.chatbot {
    @apply row flex-grow mb-4 h-full;

    & .nav-chatbot {
        & .nav-item {
            & .nav-link {
                @apply flex items-center text-[0.8125rem] text-gray-700 hover:text-primary-800 hover:bg-gray-50 h-9;

                &.active {
                    @apply bg-gray-100 text-gray-700;
                }
            }
        }
    }


    & .chatbot-message {
        @apply mb-2 text-gray-900;

        & .chatbot-message-content {
            @apply py-2 px-3 leading-relaxed break-words;

            /* Fix for paragraph styling */
            & p {
                @apply mb-4 last:mb-0;
            }

            /* Fix for list styling */
            & ul {
                @apply list-disc pl-6 my-3;
            }
            
            & ol {
                @apply list-decimal pl-6 my-3;
            }
            
            & ul li,
            & ol li {
                @apply mb-1;
            }
            
            /* Nested lists */
            & ul ul,
            & ol ol,
            & ul ol,
            & ol ul {
                @apply my-1;
            }
            
            & ul ul {
                @apply list-[circle];
            }
            
            & ol ol {
                @apply list-[lower-alpha];
            }
            
            /* Code blocks */
            & pre {
                @apply bg-gray-50 rounded p-4 overflow-x-auto my-3;
            }
            
            & code {
                @apply font-mono text-sm p-0.5 px-1 bg-gray-100 rounded;
            }
            
            & pre code {
                @apply bg-transparent p-0;
            }
        }
        
        &.user {
            @apply max-w-[85%] ml-auto;

            & .chatbot-message-content {
                @apply bg-gray-100 rounded;
            }
        }
        
        &.assistant {
            @apply mr-auto;
        }

        &.system {
            @apply mr-auto;

            & .chatbot-message-content {
                @apply text-gray-600 font-medium rounded w-auto text-xs relative pl-8 flex items-center;
                
                /* Add spinner with ::before pseudo-element */
                &::before {
                    @apply absolute left-3 w-3 h-3 border-2 border-t-transparent border-gray-400 rounded-full animate-spin;
                    content: "";
                }
            }
        }
    }

    & #chatbot-cancel-icon {
        @apply hidden;
    }
    

    &.is-processing {
        & #chatbot-cancel-icon {
            @apply inline-flex;
        }
        
        & #chatbot-submit-icon {
            @apply hidden;
        }
    }
}